<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        节点分组管理({{name}})
                        <v-spacer></v-spacer>
                        <div>
                            <v-btn class="mx-2" color="warning" dark small @click="$router.back(-1)">
                                <v-icon small dark>mdi-arrow-left</v-icon>返回
                            </v-btn>
                            <v-btn class="mx-2" dark small color="cyan" @click="dialog=true;editType='add'">
                                <v-icon small dark>mdi-plus</v-icon>添加
                            </v-btn>
                            <v-btn class="mx-2" dark small color="teal" @click="statusChange(true)">
                                批量开启
                            </v-btn>
                            <v-btn class="mx-2" dark small color="pink" @click="statusChange(false)">
                                批量关闭
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-form>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-select dense :items="netItems" v-model="netType">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-btn small color="primary" class="mr-4" @click="getServe">
                                        服务器探针
                                    </v-btn>
                                    <v-btn small color="success" class="mr-4" @click="getOnline">
                                        在线人数
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-data-table :headers="headers" disable-sort :items="groupList" :loading="loading" hide-default-footer :server-items-length="groupList.length" show-select v-model="selected" class="elevation-1">
                            <template v-slot:item.created_at="{ item }">
                                <span>{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
                            </template>
                            <template v-slot:item.enabled="{ item }">
                                <v-switch v-model="item.enabled" @change="changeStatus(item)"></v-switch>
                            </template>
                            <template v-slot:item.option="{ item }">
                                <div style="display:flex;justify-content:flex-start">
                                    <v-btn class="mx-2" dark small color="cyan" @click="editGroup(item)">
                                        <v-icon x-small dark>mdi-pencil</v-icon>编辑
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="purple" @click="node(item)">
                                        <v-icon x-small dark>mdi-call-split</v-icon>节点
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="pink" @click="del(item)">
                                        <v-icon x-small dark>mdi-delete-outline</v-icon>删除
                                    </v-btn>
                                </div>

                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        <span class="headline">分组</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="addForm.name" label="名称" :rules="[v => !!v || '不能为空']" required></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="4">
                    <v-text-field v-model="addForm.tag" label="标签" :rules="[v => !!v || '不能为空']" required></v-text-field>
                  </v-col> -->
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="addForm.order" label="排序" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div style="display:flex;justify-content:flex-start">
                                            <span style="font-size:16px;width:100px;line-height:30px;">tag(标签)</span>
                                            <vselect class="cwhsi" style="width:100%" :options="options" v-model="addForm.tag" :reduce="country => country.en" label="name" placeholder="标签" required>
                                                <template v-slot:option="option">
                                                    <img :src="option.src" alt="" width="15">
                                                    {{ option.name }}
                                                </template>
                                            </vselect>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-card-title>
                                        <h3>模式:</h3>
                                    </v-card-title>
                                    <v-checkbox v-model="rh" class="mx-2" label="融合"></v-checkbox>
                                    <v-checkbox v-model="qj" class="mx-2" label="全局"></v-checkbox>
                                    <v-checkbox v-model="cg" class="mx-2" label="出国"></v-checkbox>
                                    <v-checkbox v-model="hg" class="mx-2" label="回国"></v-checkbox>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false;addForm={order:100};rh=false;qj=true;cg=true;hg=false">取消</v-btn>
                        <v-btn color="blue darken-1" :disabled="!valid" text @click="save">确定</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>

<script>
    // @ is an alias to /src
    import { post, tips } from '@/facade';
    import { country_flag } from '@/country';
    export default {
        data() {
            return {
                options: [],
                nid: "",
                loading: true,
                headers: [
                    { text: 'ID', value: 'id' },
                    { text: '名称', value: 'name' },
                    { text: '标签', value: 'tag' },
                    { text: '模式', value: 'mode' },
                    { text: '机器总数', value: 'count' },
                    { text: '开启', value: 'enabled' },
                    { text: '排序', value: 'order' },
                    { text: '服务器', value: 'serve' },
                    { text: '在线人数', value: 'online' },
                    { text: '创建时间', value: 'created_at' },
                    { text: '操作', value: 'option' },
                ],
                rh: false,
                qj: true,
                cg: true,
                hg: false,
                form: {},
                dialog: false,
                valid: true,
                addForm: { order: 100 },
                editType: "add",
                selected: [],
                netItems: [
                    { text: '转发网', value: 'fnet' },
                    { text: '本地网', value: 'lnet' },
                    { text: '虚拟网', value: 'vnet' },
                ],
                netType: 'fnet',
                num: 0,
                name: "",
                selected2: ""
            }
        },
        watch: {
            '$route'(to, from) { // 监听路由是否变化
                if (to.query.id !== from.query.id) {
                    this.nid = to.query.id;
                    this.name = to.query.name;
                    this.getList();
                }
            }
        },
        computed: {
            groupList() {
                return this.$store.state.groupList;
            }
        },
        async created() {
            const info = localStorage.getItem('netInfo')
            this.netInfo = JSON.parse(info);
            this.name = this.netInfo.name;
            this.nid = this.netInfo.id;
            await this.getList();
            this.options = country_flag.map(item => ({ name: `${item.en}(${item.name})`, en: item.en, src: item.src }));
            // if (this.$route.query.id) {
            //   this.nid = this.$route.query.id;
            //   this.name = this.$route.query.name;
            //   await this.getList();
            // }
        },

        methods: {
            /**获取列表 */
            async getList() {
                const modes = ['融合', '全局', '出国', '回国'];
                this.loading = true;
                const data = await post('/vnet/group/list', { nid: this.nid })
                if (data.code == 'ok') {
                    this.loading = false;
                    data.groups.forEach((e) => {
                        e.count = `${(e.fnet_use+e.lnet_use+e.vnet_use)}/${(e.fnet_all+e.lnet_all+e.vnet_all)}`;
                        e.mode = e.modes.map(m => modes[m]).join(',');
                        e.serve = '';
                        e.online = '';
                    })
                    this.$store.commit('changeGroupList', data.groups)
                } else {
                    tips('error', data.message)
                    this.loading = false;
                }
            },
            /**添加确认节点 */
            async save() {
                const value = this.$refs.form.validate()
                if (value) {
                    if (this.editType == 'add') {
                        let list = [];
                        if (this.rh) list.push(0)
                        if (this.qj) list.push(1)
                        if (this.cg) list.push(2)
                        if (this.hg) list.push(3)
                        const form = Object.assign({}, this.addForm)
                        form.nid = this.nid;
                        form.modes = list;
                        form.enabled = true;
                        const data = await post('/vnet/group/create', form)
                        if (data.code == 'ok') {
                            tips('success', '添加成功')
                            this.dialog = false;
                            this.rh = false;
                            this.qj = true;
                            this.cg = true;
                            this.hg = false;
                            this.addForm = { order: 100 }
                            this.getList();
                        } else {
                            tips('error', data.message)
                        }
                    } else {
                        let list = [];
                        if (this.rh) list.push(0)
                        if (this.qj) list.push(1)
                        if (this.cg) list.push(2)
                        if (this.hg) list.push(3)
                        const form = {
                            gid: this.addForm.id,
                            name: this.addForm.name,
                            region: this.addForm.region,
                            order: this.addForm.order,
                            modes: list,
                            enabled: !!this.addForm.enabled,
                            tag: this.addForm.tag
                        }
                        const data = await post('/vnet/group/update', form)
                        if (data.code == 'ok') {
                            tips('success', '更改成功')
                            this.dialog = false;
                            this.rh = false;
                            this.qj = true;
                            this.cg = true;
                            this.hg = false;
                            this.addForm = { order: 100 }
                            this.getList();
                        } else {
                            tips('error', data.message)
                        }
                    }

                }
            },
            /**在已有的分组里选择 */
            groupChange() {
                const list = this.ctys2.filter(item => item.text == this.addForm.group)
                this.addForm.region = list[0].value
            },
            /**删除 */
            async del(item) {
                const value = await confirm('即将删除，是否继续')
                if (value) {
                    const data = await post('/vnet/group/delete', { gid: item.id })
                    if (data.code == 'ok') {
                        tips('success', '删除成功')
                        this.getList()
                    } else {
                        tips('error', data.message)
                    }
                }
            },
            /**调转节点 */
            node(item) {
                localStorage.setItem('netId', this.nid)
                this.$router.push({ path: '/node', query: { id: item.id, name: item.name } })
            },
            /**点击编辑 */
            editGroup(item) {
                this.addForm = item;
                this.dialog = true;
                this.editType = 'edit';
                if (item.mode.indexOf('融合') != -1) this.rh = true
                else this.rh = false
                if (item.mode.indexOf('全局') != -1) this.qj = true
                else this.qj = false
                if (item.mode.indexOf('出国') != -1) this.cg = true
                else this.cg = false
                if (item.mode.indexOf('回国') != -1) this.hg = true
                else this.hg = false
            },
            /**更改状态 */
            async changeStatus(item) {
                // const value = await confirm('即将更改状态，是否继续')
                let list = [];
                if (this.rh) list.push(0)
                if (this.qj) list.push(1)
                if (this.cg) list.push(2)
                if (this.hg) list.push(3)
                const form = {
                    gid: item.id,
                    name: item.name,
                    region: item.region,
                    order: item.order,
                    modes: list,
                    enabled: !!item.enabled
                }
                const data = await post('/vnet/group/update', form)
                if (data.code == 'ok') {
                    tips('success', '更改成功')
                    this.dialog = false;
                    this.rh = false;
                    this.qj = true;
                    this.cg = true;
                    this.hg = false;
                    this.addForm = { order: 100 }
                    this.getList();
                } else {
                    tips('error', data.message)
                }
            },
            /**批量更改状态 */
            async statusChange(param) {
                if (this.selected.length > 0) {
                    const gids = this.selected.map(item => item.id)
                    const form = {
                        nid: this.nid,
                        enabled: param,
                        gids: gids
                    }
                    const data = await post('/vnet/group/status', form)
                    if (data.code == 'ok') {
                        tips('success', '更改成功')
                        this.selected = []
                        this.getList();
                    } else {
                        tips('error', data.message)
                    }
                } else {
                    tips('error', '请先选择节点组')
                }
            },
            /**获取服务器数 */
            async getServe() {
                if (this.selected.length > 0) {
                    this.selected.forEach(async item => {
                        let serves = 0
                        const data = await post('/vnet/link/list', { gid: item.id, type: this.netType })
                        if (data.code == 'ok') {
                            const nodes = data.links
                            serves += nodes.length;
                            const onserve = await this.detect(nodes)
                            item.serve = `${onserve}/${serves}`
                        }
                    })
                    let groupList = this.$store.state.groupList;
                    for (var item of groupList) {
                        for (var n of this.selected) {
                            if (item.id == n.id) item.serve = n.serve
                        }
                    }
                    this.$store.commit('changeGroupList', groupList)
                } else {
                    tips('error', '请先选择组')
                }
            },
            /**在线服务器数 */
            async detect(nodes) {
                const list = nodes.map(item => {
                    const arr = item.hosts.split('-', 1)[0].split(':');
                    const ports = arr[1].split(',')
                    const host = { ip: arr[0], tcp: ports[0], udp: ports[1] };
                    return host
                })
                const form = {
                    hosts: list,
                    option: { cmd: 0 },
                    timeout: 5000
                }
                const data = await post('/vnet/link/detect', form)
                if (data.code == 'ok') {
                    const results = data.results
                    const onserve = results.filter(item => item.code == 'ok').length
                    return onserve
                }
            },
            /**获取在线人数 */
            async getOnline() {
                if (this.selected.length > 0) {
                    this.selected.forEach(async item => {
                        const data = await post('/vnet/link/list', { gid: item.id, type: this.netType })
                        if (data.code == 'ok') {
                            const nodes = data.links
                            const num = await this.online(nodes)
                            item.online = num
                        }
                    })
                    let groupList = this.$store.state.groupList;
                    for (var item of groupList) {
                        for (var n of this.selected) {
                            if (item.id == n.id) item.online = n.online
                        }
                    }
                    this.$store.commit('changeGroupList', groupList)
                } else {
                    tips('error', '请先选择组')
                }
            },
            /**在线人数 */
            async online(nodes) {
                const list = nodes.map(item => {
                    const arr = item.hosts.split('-', 1)[0].split(':');
                    const ports = arr[1].split(',')
                    const host = { ip: arr[0], tcp: ports[0], udp: ports[1] };
                    return host
                })
                const form = {
                    hosts: list,
                    option: { cmd: 9 },
                    timeout: 5000
                }
                const data = await post('/vnet/link/detect', form)
                if (data.code == 'ok') {
                    let num = 0;
                    const list = data.results.filter(item => item.code == 'ok').map(e => e.total)
                    list.forEach(n => { num += n })
                    return num
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }

    }
</style>