
export const AD  = require ('./assets/country/ad.png');
export const AE  = require ('./assets/country/ae.png');
export const AF  = require ('./assets/country/af.png');
export const AG  = require ('./assets/country/ag.png');
export const AL  = require ('./assets/country/al.png');
export const AM  = require ('./assets/country/am.png');
export const AO  = require ('./assets/country/ao.png');
export const AR  = require ('./assets/country/ar.png');
export const AT  = require ('./assets/country/at.png');
export const AZ  = require ('./assets/country/az.png');
export const BA  = require ('./assets/country/ba.png');
export const BB  = require ('./assets/country/bb.png');
export const BD  = require ('./assets/country/bd.png');
export const BE  = require ('./assets/country/be.png');
export const BF  = require ('./assets/country/bf.png');
export const BG  = require ('./assets/country/bg.png');
export const BH  = require ('./assets/country/bh.png');
export const BI  = require ('./assets/country/bi.png');
export const BJ  = require ('./assets/country/bj.png');
export const BN  = require ('./assets/country/bn.png');
export const BO  = require ('./assets/country/bo.png');
export const BR  = require ('./assets/country/br.png');
export const BS  = require ('./assets/country/bs.png');
export const BT  = require ('./assets/country/bt.png');
export const BW  = require ('./assets/country/bw.png');
export const BY  = require ('./assets/country/by.png');
export const BZ  = require ('./assets/country/bz.png');
export const CD  = require ('./assets/country/cd.png');
export const CF  = require ('./assets/country/cf.png');
export const CG  = require ('./assets/country/cg.png');
export const CH  = require ('./assets/country/ch.png');
export const CI  = require ('./assets/country/ci.png');
export const CL  = require ('./assets/country/cl.png');
export const CM  = require ('./assets/country/cm.png');
export const CO  = require ('./assets/country/co.png');
export const CR  = require ('./assets/country/cr.png');
export const CU  = require ('./assets/country/cu.png');
export const CV  = require ('./assets/country/cv.png');
export const CY  = require ('./assets/country/cy.png');
export const CZ  = require ('./assets/country/cz.png');
export const DJ  = require ('./assets/country/dj.png');
export const DK  = require ('./assets/country/dk.png');
export const DM  = require ('./assets/country/dm.png');
export const DO  = require ('./assets/country/do.png');
export const DZ  = require ('./assets/country/dz.png');
export const EC  = require ('./assets/country/ec.png');
export const EE  = require ('./assets/country/ee.png');
export const EG  = require ('./assets/country/eg.png');
export const EH  = require ('./assets/country/eh.png');
export const ER  = require ('./assets/country/er.png');
export const ES  = require ('./assets/country/es.png');
export const ET  = require ('./assets/country/et.png');
export const FI  = require ('./assets/country/fi.png');
export const FJ  = require ('./assets/country/fj.png');
export const FM  = require ('./assets/country/fm.png');
export const GA  = require ('./assets/country/ga.png');
export const GD  = require ('./assets/country/gd.png');
export const GE  = require ('./assets/country/ge.png');
export const GH  = require ('./assets/country/gh.png');
export const GM  = require ('./assets/country/gm.png');
export const GN  = require ('./assets/country/gn.png');
export const GQ  = require ('./assets/country/gq.png');
export const GR  = require ('./assets/country/gr.png');
export const GT  = require ('./assets/country/gt.png');
export const GW  = require ('./assets/country/gw.png');
export const GY  = require ('./assets/country/gy.png');
export const HN  = require ('./assets/country/hn.png');
export const HR  = require ('./assets/country/hr.png');
export const HT  = require ('./assets/country/ht.png');
export const HU  = require ('./assets/country/hu.png');
export const ID  = require ('./assets/country/id.png');
export const IE  = require ('./assets/country/ie.png');
export const IL  = require ('./assets/country/il.png');
export const IQ  = require ('./assets/country/iq.png');
export const IR  = require ('./assets/country/ir.png');
export const IS  = require ('./assets/country/is.png');
export const JM  = require ('./assets/country/jm.png');
export const JO  = require ('./assets/country/jo.png');
export const KE  = require ('./assets/country/ke.png');
export const KG  = require ('./assets/country/kg.png');
export const KH  = require ('./assets/country/kh.png');
export const KI  = require ('./assets/country/ki.png');
export const KM  = require ('./assets/country/km.png');
export const KN  = require ('./assets/country/kn.png');
export const KP  = require ('./assets/country/kp.png');
export const KS  = require ('./assets/country/ks.png');
export const KW  = require ('./assets/country/kw.png');
export const KZ  = require ('./assets/country/kz.png');
export const LA  = require ('./assets/country/la.png');
export const LB  = require ('./assets/country/lb.png');
export const LC  = require ('./assets/country/lc.png');
export const LI  = require ('./assets/country/li.png');
export const LK  = require ('./assets/country/lk.png');
export const LR  = require ('./assets/country/lr.png');
export const LS  = require ('./assets/country/ls.png');
export const LT  = require ('./assets/country/lt.png');
export const LU  = require ('./assets/country/lu.png');
export const LV  = require ('./assets/country/lv.png');
export const LY  = require ('./assets/country/ly.png');
export const MA  = require ('./assets/country/ma.png');
export const MC  = require ('./assets/country/mc.png');
export const MD  = require ('./assets/country/md.png');
export const ME  = require ('./assets/country/me.png');
export const MG  = require ('./assets/country/mg.png');
export const MH  = require ('./assets/country/mh.png');
export const MK  = require ('./assets/country/mk.png');
export const ML  = require ('./assets/country/ml.png');
export const MM  = require ('./assets/country/mm.png');
export const MN  = require ('./assets/country/mn.png');
export const MR  = require ('./assets/country/mr.png');
export const MT  = require ('./assets/country/mt.png');
export const MU  = require ('./assets/country/mu.png');
export const MV  = require ('./assets/country/mv.png');
export const MW  = require ('./assets/country/mw.png');
export const MX  = require ('./assets/country/mx.png');
export const MY  = require ('./assets/country/my.png');
export const MZ  = require ('./assets/country/mz.png');
export const NA  = require ('./assets/country/na.png');
export const NE  = require ('./assets/country/ne.png');
export const NG  = require ('./assets/country/ng.png');
export const NI  = require ('./assets/country/ni.png');
export const NO  = require ('./assets/country/no.png');
export const NP  = require ('./assets/country/np.png');
export const NR  = require ('./assets/country/nr.png');
export const NZ  = require ('./assets/country/nz.png');
export const OM  = require ('./assets/country/om.png');
export const PA  = require ('./assets/country/pa.png');
export const PE  = require ('./assets/country/pe.png');
export const PG  = require ('./assets/country/pg.png');
export const PH  = require ('./assets/country/ph.png');
export const PK  = require ('./assets/country/pk.png');
export const PL  = require ('./assets/country/pl.png');
export const PT  = require ('./assets/country/pt.png');
export const PW  = require ('./assets/country/pw.png');
export const PY  = require ('./assets/country/py.png');
export const QA  = require ('./assets/country/qa.png');
export const RO  = require ('./assets/country/ro.png');
export const RS  = require ('./assets/country/rs.png');
export const RU  = require ('./assets/country/ru.png');
export const RW  = require ('./assets/country/rw.png');
export const SA  = require ('./assets/country/sa.png');
export const SB  = require ('./assets/country/sb.png');
export const SC  = require ('./assets/country/sc.png');
export const SD  = require ('./assets/country/sd.png');
export const SE  = require ('./assets/country/se.png');
export const SI  = require ('./assets/country/si.png');
export const SK  = require ('./assets/country/sk.png');
export const SL  = require ('./assets/country/sl.png');
export const SM  = require ('./assets/country/sm.png');
export const SN  = require ('./assets/country/sn.png');
export const SO  = require ('./assets/country/so.png');
export const SR  = require ('./assets/country/sr.png');
export const ST  = require ('./assets/country/st.png');
export const SV  = require ('./assets/country/sv.png');
export const SY  = require ('./assets/country/sy.png');
export const SZ  = require ('./assets/country/sz.png');
export const TD  = require ('./assets/country/td.png');
export const TG  = require ('./assets/country/tg.png');
export const TH  = require ('./assets/country/th.png');
export const TJ  = require ('./assets/country/tj.png');
export const TL  = require ('./assets/country/tl.png');
export const TM  = require ('./assets/country/tm.png');
export const TN  = require ('./assets/country/tn.png');
export const TO  = require ('./assets/country/to.png');
export const TR  = require ('./assets/country/tr.png');
export const TT  = require ('./assets/country/tt.png');
export const TV  = require ('./assets/country/tv.png');
export const TW  = require ('./assets/country/tw.png');
export const TZ  = require ('./assets/country/tz.png');
export const UA  = require ('./assets/country/ua.png');
export const UG  = require ('./assets/country/ug.png');
export const UY  = require ('./assets/country/uy.png');
export const UZ  = require ('./assets/country/uz.png');
export const VA  = require ('./assets/country/va.png');
export const VC  = require ('./assets/country/vc.png');
export const VE  = require ('./assets/country/ve.png');
export const VN  = require ('./assets/country/vn.png');
export const VU  = require ('./assets/country/vu.png');
export const WS  = require ('./assets/country/ws.png');
export const YE  = require ('./assets/country/ye.png');
export const ZA  = require ('./assets/country/za.png');
export const ZM  = require ('./assets/country/zm.png');
export const ZW  = require ('./assets/country/zw.png');
export const AI = require('./assets/country/ai.png');
export const AN = require('./assets/country/an.png');
export const AS = require('./assets/country/as.png');
export const AW = require('./assets/country/aw.png');
export const BM = require('./assets/country/bm.png');
export const CK = require('./assets/country/ck.png');
export const FK = require('./assets/country/fk.png');
export const FO = require('./assets/country/fo.png');
export const GF = require('./assets/country/gf.png');
export const GI = require('./assets/country/gi.png');
export const GL = require('./assets/country/gl.png');
export const KY = require('./assets/country/ky.png');
export const MO = require('./assets/country/mo.png');
export const MS = require('./assets/country/ms.png');
export const NC = require('./assets/country/nc.png');
export const NU = require('./assets/country/nu.png');
export const PF = require('./assets/country/pf.png');
export const PM = require('./assets/country/pm.png');
export const PR = require('./assets/country/pr.png');
export const RE = require('./assets/country/re.png');
export const SS = require('./assets/country/ss.png');
export const TC = require('./assets/country/tc.png');
export const VG = require('./assets/country/vg.png');


export const AU  = require ('./assets/country/au.png');
export const CN  = require ('./assets/country/cn.png');
export const CA  = require ('./assets/country/ca.png');
export const DE  = require ('./assets/country/de.png');
export const FR  = require ('./assets/country/fr.png');
export const GB  = require ('./assets/country/gb.png');
export const HK  = require ('./assets/country/hk.png');
export const IN  = require ('./assets/country/in.png');
export const IT  = require ('./assets/country/it.png');
export const JP  = require ('./assets/country/jp.png');
export const KR  = require ('./assets/country/kr.png');
export const NL  = require ('./assets/country/nl.png');
export const SG  = require ('./assets/country/sg.png');
export const UN  = require ('./assets/country/un.png');
export const US  = require ('./assets/country/us.png');

    export const country_flag = [
        { name: "安道尔",src:AD,en:'ad'},
        { name: "阿拉伯",src:AE,en:'ae'},
        { name: "阿富汗",src:AF,en:'af'},
        { name: "安提瓜和巴布达",src:AG,en:'ag'},
        { name: "安圭拉",src:AI,en:'ai'},
        { name: "阿尔巴",src:AL,en:'al'},
        { name: "亚美尼亚",src:AM,en:'am'},
        { name: "列斯群岛",src:AN,en:'an'},
        { name: "安哥拉",src:AO,en:'ao'},
        { name: "阿根廷",src:AR,en:'ar'},
        { name: "美属萨摩亚",src:AS,en:'as'},
        { name: "奥地利",src:AT,en:'at'},
        { name: "澳大利亚", src: AU, en: 'au' },
        { name: "阿鲁巴", src: AW, en: 'aw' },
        { name: "阿塞拜疆",src:AZ,en:'az'},
        { name: "波黑",src:BA,en:'ba'},
        { name: "巴巴多斯",src:BB,en:'bb'},
        { name: "孟加拉",src:BD,en:'bd'},
        { name: "切时",src:BE,en:'be'},
        { name: "布基纳法索",src:BF,en:'bf'},
        { name: "保加利亚",src:BG,en:'bg'},
        { name: "巴林",src:BH,en:'bh'},
        { name: "布融",src:BI,en:'bi'},
        { name: "贝宁",src:BJ,en:'bj'},
        { name: "百慕大",src:BM,en:'bm'},
        { name: "文莱",src:BN,en:'bn'},
        { name: "玻利维亚",src:BO,en:'bo'},
        { name: "巴西",src:BR,en:'br'},
        { name: "巴哈马",src:BS,en:'bs'},
        { name: "不丹",src:BT,en:'bt'},
        { name: "博茨瓦纳",src:BW,en:'bw'},
        { name: "白俄罗斯",src:BY,en:'by'},
        { name: "伯利兹",src:BZ,en:'bz'},
        { name: "加拿大", src: CA, en: 'ca' },
        { name: "刚果(金)",src:CD,en:'cd'},
        { name: "中非",src:CF,en:'cf'},
        { name: "刚果(布)",src:CG,en:'cg'},
        { name: "瑞士",src:CH,en:'ch'},
        { name: "科特迪瓦",src:CI,en:'ci'},
        { name: "库克群岛",src:CK,en:'ck'},
        { name: "智利",src:CL,en:'cl'},
        { name: "喀麦隆",src:CM,en:'cm'},
        { name: "中国", src: CN, en: 'cn' },
        { name: "哥伦比亚",src:CO,en:'co'},
        { name: "哥斯达黎加",src:CR,en:'cr'},
        { name: "古巴",src:CU,en:'cu'},
        { name: "佛得角",src:CV,en:'cv'},
        { name: "塞浦路斯",src:CY,en:'cy'},
        { name: "捷克",src:CZ,en:'cz'},
        { name: "德国", src: DE, en: 'de' },
        { name: "吉布提",src:DJ,en:'dj'},
        { name: "丹麦",src:DK,en:'dk'},
        { name: "多米尼加",src:DM,en:'dm'},
        { name: "多明尼加",src:DO,en:'do'},
        { name: "阿尔及利亚",src:DZ,en:'dz'},
        { name: "厄瓜多尔",src:EC,en:'ec'},
        { name: "爱沙尼亚",src:EE,en:'ee'},
        { name: "埃及",src:EG,en:'eg'},
        { name: "西萨摩亚",src:EH,en:'eh'},
        { name: "厄立特",src:ER,en:'er'},
        { name: "西班牙",src:ES,en:'es'},
        { name: "埃塞俄比亚",src:ET,en:'et'},
        { name: "芬兰",src:FI,en:'fi'},
        { name: "斐济",src:FJ,en:'fj'},
        { name: "法兰克岛",src:FK,en:'fk'},
        { name: "密联邦",src:FM,en:'fm'},
        { name: "法罗群岛",src:FO,en:'fo'},
        { name: "法国", src: FR, en: 'fr' },
        { name: "加蓬",src:GA,en:'ga'},
        { name: "英国", src: GB, en: 'gb' },
        { name: "格林纳达",src:GD,en:'gd'},
        { name: "格鲁吉亚",src:GE,en:'ge'},
        { name: "法属圭亚那",src:GF,en:'gf'},
        { name: "加纳",src:GH,en:'gh'},
        { name: "直布罗陀",src:GI,en:'gi'},
        { name: "格陵兰",src:GL,en:'gl'},
        { name: "冈比亚",src:GM,en:'gm'},
        { name: "几内亚",src:GN,en:'gn'},
        { name: "赤道几内亚",src:GQ,en:'gq'},
        { name: "希腊",src:GR,en:'gr'},
        { name: "危地马拉",src:GT,en:'gt'},
        { name: "比绍",src:GW,en:'gw'},
        { name: "却那",src:GY,en:'gy'},
        { name: "香港", src: HK, en: 'hk' },
        { name: "洪都拉斯",src:HN,en:'hn'},
        { name: "克罗地亚",src:HR,en:'hr'},
        { name: "海地",src:HT,en:'ht'},
        { name: "匈牙利",src:HU,en:'hu'},
        { name: "印度尼西亚",src:ID,en:'id'},
        { name: "爱尔兰",src:IE,en:'ie'},
        { name: "以色列",src:IL,en:'il'},
        { name: "印度", src: IN, en: 'in' },
        { name: "伊拉克",src:IQ,en:'iq'},
        { name: "伊朗",src:IR,en:'ir'},
        { name: "冰岛",src:IS,en:'is'},
        { name: "意大利", src: IT, en: 'it' },
        { name: "牙买加",src:JM,en:'jm'},
        { name: "约旦",src:JO,en:'jo'},
        { name: "日本", src: JP, en: 'jp' },
        { name: "肯尼亚",src:KE,en:'ke'},
        { name: "吉尔吉斯坦",src:KG,en:'kg'},
        { name: "吉尔吉斯斯坦",src:KS,en:'ks'},
        { name: "柬埔寨",src:KH,en:'kh'},
        { name: "基里巴斯",src:KI,en:'ki'},
        { name: "科摩罗",src:KM,en:'km'},
        { name: "圣基茨和尼维斯",src:KN,en:'kn'},
        { name: "朝鲜",src:KP,en:'kp'},
        { name: "韩国", src: KR, en: 'kr' },
        { name: "科威特",src:KW,en:'kw'},
        { name: "开曼群岛",src:KY,en:'ky'},
        { name: "哈萨克斯坦",src:KZ,en:'kz'},
        { name: "老挝",src:LA,en:'la'},
        { name: "黎巴嫩",src:LB,en:'lb'},
        { name: "圣卢西亚",src:LC,en:'lc'},
        { name: "列支敦士登",src:LI,en:'li'},
        { name: "斯里兰卡",src:LK,en:'lk'},
        { name: "利比里亚",src:LR,en:'lr'},
        { name: "莱索托",src:LS,en:'ls'},
        { name: "立陶宛",src:LT,en:'lt'},
        { name: "卢森堡",src:LU,en:'lu'},
        { name: "拉脱维亚",src:LV,en:'lv'},
        { name: "利比亚",src:LY,en:'ly'},
        { name: "摩洛哥",src:MA,en:'ma'},
        { name: "摩纳哥",src:MC,en:'mc'},
        { name: "摩尔多瓦",src:MD,en:'md'},
        { name: "黑山",src:ME,en:'me'},
        { name: "马达加斯加",src:MG,en:'mg'},
        { name: "马绍尔群岛",src:MH,en:'mh'},
        { name: "马其顿",src:MK,en:'mk'},
        { name: "马里",src:ML,en:'ml'},
        { name: "缅甸",src:MM,en:'mm'},
        { name: "蒙古",src:MN,en:'mn'},
        { name: "澳门",src:MO,en:'mo'},
        { name: "毛里塔尼亚",src:MR,en:'mr'},
        { name: "蒙塞拉特",src:MS,en:'ms'},
        { name: "马耳他",src:MT,en:'mt'},
        { name: "毛里求斯",src:MU,en:'mu'},
        { name: "马尔代夫",src:MV,en:'mv'},
        { name: "马拉维",src:MW,en:'mw'},
        { name: "墨西哥",src:MX,en:'mx'},
        { name: "马来西亚",src:MY,en:'my'},
        { name: "莫桑比克",src:MZ,en:'mz'},
        { name: "纳米比亚",src:NA,en:'na'},
        { name: "新喀里多尼亚",src:NC,en:'nc'},
        { name: "尼日尔",src:NE,en:'ne'},
        { name: "尼日利亚",src:NG,en:'ng'},
        { name: "尼加拉瓜",src:NI,en:'ni'},
        { name: "荷兰", src: NL, en: 'nl' },
        { name: "挪威",src:NO,en:'no'},
        { name: "尼泊尔",src:NP,en:'np'},
        { name: "瑙鲁",src:NR,en:'nr'},
        { name: "纽埃",src:NU,en:'nu'},
        { name: "新西兰",src:NZ,en:'nz'},
        { name: "阿曼",src:OM,en:'om'},
        { name: "巴拿马",src:PA,en:'pa'},
        { name: "秘鲁",src:PE,en:'pe'},
        { name: "法国波利尼西亚",src:PF,en:'pf'},
        { name: "巴布亚新几内亚",src:PG,en:'pg'},
        { name: "菲律宾",src:PH,en:'ph'},
        { name: "巴基斯坦",src:PK,en:'pk'},
        { name: "波兰",src:PL,en:'pl'},
        { name: "圣皮埃尔和密克隆",src:PM,en:'pm'},
        { name: "波多黎各",src:PR,en:'pr'},
        { name: "葡萄牙",src:PT,en:'pt'},
        { name: "帕劳",src:PW,en:'pw'},
        { name: "巴拉圭",src:PY,en:'py'},
        { name: "卡塔尔",src:QA,en:'qa'},
        { name: "留尼旺",src:RE,en:'re'},
        { name: "罗马尼亚",src:RO,en:'ro'},
        { name: "塞尔维亚",src:RS,en:'rs'},
        { name: "俄罗斯",src:RU,en:'ru'},
        { name: "卢旺达",src:RW,en:'rw'},
        { name: "沙特阿拉伯",src:SA,en:'sa'},
        { name: "索罗蒙",src:SB,en:'sb'},
        { name: "塞舌尔",src:SC,en:'sc'},
        { name: "苏丹",src:SD,en:'sd'},
        { name: "瑞典",src:SE,en:'se'},
        { name: "新加坡", src: SG, en: 'sg' },
        { name: "斯洛文尼亚",src:SI,en:'si'},
        { name: "斯洛伐克",src:SK,en:'sk'},
        { name: "塞拉利昂",src:SL,en:'sl'},
        { name: "圣马力诺",src:SM,en:'sm'},
        { name: "塞内加尔",src:SN,en:'sn'},
        { name: "索马里",src:SO,en:'so'},
        { name: "苏里南",src:SR,en:'sr'},
        { name: "南苏丹",src:SS,en:'ss'},
        { name: "圣多美和普林西比",src:ST,en:'st'},
        { name: "萨尔瓦多",src:SV,en:'sv'},
        { name: "叙利亚",src:SY,en:'sy'},
        { name: "斯威士兰",src:SZ,en:'sz'},
        { name: "特克斯和凯科斯群岛",src:TC,en:'tc'},
        { name: "乍得",src:TD,en:'td'},
        { name: "多哥",src:TG,en:'tg'},
        { name: "泰国",src:TH,en:'th'},
        { name: "塔吉克斯坦",src:TJ,en:'tj'},
        { name: "帝汶岛",src:TL,en:'tl'},
        { name: "土库曼斯坦",src:TM,en:'tm'},
        { name: "突尼斯",src:TN,en:'tn'},
        { name: "汤加",src:TO,en:'to'},
        { name: "土耳其",src:TR,en:'tr'},
        { name: "特立尼达和多巴哥",src:TT,en:'tt'},
        { name: "图瓦卢",src:TV,en:'tv'},
        { name: "台湾",src:TW,en:'tw'},
        { name: "坦桑尼亚",src:TZ,en:'tz'},
        { name: "乌克兰",src:UA,en:'ua'},
        { name: "乌干达",src:UG,en:'ug'},
        { name: "UN",src:UN,en:'un'},
        { name: "美国", src: US, en: 'us' },
        { name: "乌拉圭",src:UY,en:'uy'},
        { name: "乌兹别克斯坦",src:UZ,en:'uz'},
        { name: "弗吉尼亚",src:VA,en:'va'},
        { name: "圣文森特和格林纳丁斯",src:VC,en:'vc'},
        { name: "委内瑞拉",src:VE,en:'ve'},
        { name: "维尔京群岛",src:VG,en:'vg'},
        { name: "越南",src:VN,en:'vn'},
        { name: "瓦努阿图",src:VU,en:'yu'},
        { name: "朝斯和富图纳",src:FR,en:'wf'},
        { name: "萨摩亚",src:WS,en:'ws'},
        { name: "国际网络",src:UN,en:'xv'},
        { name: "也门",src:YE,en:'ye'},
        { name: "南非",src:ZA,en:'za'},
        { name: "赞比亚",src:ZM,en:'zm'},
        { name: "津巴布韦",src:ZW,en:'zw'},
    ];